<template>
  <div>
    <data-entry-edit></data-entry-edit>
  </div>
</template>

<script>
import DataEntryEdit from "@/components/ecologist/dataEntry/dataEntryEdit";
export default {
  name: "DataEntryEditView",
  components: { DataEntryEdit },
};
</script>

<style scoped></style>
